import React from "react";
import SignupForm from "../forms/SignupForm.js";

import "./SignupPage.css";

const SignupPage = () => {
  return (
    <div id="signup_page" data-testid="signup_page">
      <SignupForm />
      {/* <footer>Powered By <a>Playfab</a></footer> */}
    </div>
  );
};

export default SignupPage;
