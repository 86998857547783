import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import AppSettings from "../shared/appSettings.json";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Logo from "../shared/images/EvolveLogoTarget.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import "./SignupForm.css";

const SignupForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [version, setVersion] = useState(null);
  const [errors, setErrors] = useState({ error: true, message: "" });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const inactivityTime = 120000;
  const [inactivityTimer, setInactivityTimer] = useState(inactivityTime);
  const [successTimeoutId, setSuccessTimeoutId] = useState(null);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });
  const [rangeInfo, setRangeInfo] = useState({ rangeId: 0, laneId: 0 });

  const url = useLocation();

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisibility(!passwordVisibility);
  };

  const updateValue = (event) => {
    event.preventDefault();
    const nextFormState = {
      ...form,
      [event.target.name]: event.target.value,
    };
    setForm(nextFormState);
    setInactivityTimer(inactivityTimer);
  };

  const validateField = (
    name,
    value,
    minLength,
    errorMessage,
    customValidation = null
  ) => {
    if (
      value === "" ||
      value.length < minLength ||
      (customValidation && !customValidation(value))
    ) {
      setErrors({ error: true, message: errorMessage, field: name });
      console.log(errorMessage);
      return false;
    }

    // Clear error for the specific field
    setErrors({ error: false, message: "", field: "" });
    return true;
  };

  const isValidEmail = (email) => {
    // Use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    // Reset errors before checking
    setErrors({ error: false, message: "" });

    if (
      !validateField(
        "firstName",
        form.firstName,
        3,
        "Please enter a different first name over 3 characters."
      ) ||
      !validateField(
        "lastName",
        form.lastName,
        3,
        "Please enter a different last name over 3 characters."
      ) ||
      !validateField(
        "confirmEmail",
        form.confirmEmail,
        0,
        "Emails did not match. Please try again.",
        (value) => value === form.email
      ) ||
      !validateField(
        "email",
        form.email,
        1,
        "Please enter a valid email address.",
        isValidEmail
      ) ||
      !validateField(
        "confirmPassword",
        form.confirmPassword,
        6,
        "Password did not match. Please try again."
      ) ||
      !validateField(
        "password",
        form.password,
        6,
        "Please enter a valid password over 6 characters."
      )
    ) {
      setInterval(() => {
        setLoading(false);
      }, 2000);
      return false;
    }
    setLoading(false);
    return true;
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const newUser = {
      Email: form.email,
      FirstName: form.firstName,
      LastName: form.lastName,
      Password: form.password,
      RangeId: rangeInfo.rangeId,
      LaneId: rangeInfo.laneId,
    };

    await axios
      .post(`${process.env.REACT_APP_BACKEND}/api/user-routes/signup`, newUser)
      .then((response) => {
        if (response.status !== 200) {
          setErrors({ error: true, message: response.data.message });
          console.log(response.data.message);
        } else {
          const successTimeout = setTimeout(() => {
            setLoading(false);
            navigate("/success");
          }, 2000);
          setSuccessTimeoutId(successTimeout);
        }
      })
      .catch((err) => {
        setErrors({ error: true, message: err.response.data.message });
        console.log(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    //get url params
    const queryParams = new URLSearchParams(url.search);
    const rangeValue = queryParams.get("rangeId");
    const laneValue = queryParams.get("laneId");

    setRangeInfo({ rangeId: rangeValue, laneId: laneValue });

    let date = new Date();
    setDate(date.getFullYear());
    setVersion(AppSettings.version);

    return () => {
      if (successTimeoutId) clearTimeout(successTimeoutId);
    };
  }, [successTimeoutId, url.search]);

  return (
    <>
      <div id="signup_form-container" data-testid="signup_form">
        <Container maxWidth="sm">
          <Grid container justifyContent="center">
            <img src={Logo} className="signup_form__logo" alt="evolve logo" />
          </Grid>
          <Typography variant="h4" className="signup_form-h1">
            Sign Up
          </Typography>
          {errors.error && (
            <Typography
              data-testid="ErrorMessage"
              variant="body2"
              className="signup_form__error-message"
            >
              {errors.message}
            </Typography>
          )}
          <form id="signup_form" onSubmit={submitForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First name"
                  name="firstName"
                  onChange={updateValue}
                  value={form.firstName}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                  onChange={updateValue}
                  value={form.lastName}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  onChange={updateValue}
                  value={form.email}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm email"
                  name="confirmEmail"
                  onChange={updateValue}
                  value={form.confirmEmail}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password or PIN"
                  name="password"
                  type={passwordVisibility ? "text" : "password"}
                  placeholder="Min 6 characters/numbers"
                  onChange={updateValue}
                  value={form.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          onMouseDown={(e) => e.preventDefault()} // Prevents focus on the button
                          edge="end"
                        >
                          {passwordVisibility ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm password or PIN"
                  name="confirmPassword"
                  type={passwordVisibility ? "text" : "password"}
                  onChange={updateValue}
                  value={form.confirmPassword}
                  required
                />
              </Grid>
            </Grid>
            <Button
              data-testid="submit"
              type="submit"
              variant="contained"
              id="signup_form-button"
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                "Sign Up"
              )}
            </Button>
          </form>
        </Container>
        <div className="signup_form-data-tag-container">
          <Typography variant="p" className="signup_form-data-tag">
            {`Copyright (C) ${date} Evolve Range Solutions v${version}`}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SignupForm;
