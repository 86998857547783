import React, { useEffect } from "react";
import AppSettings from "../shared/appSettings.json";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Logo from "../shared/images/EvolveLogoTarget.png";
import "./SuccessPage.css";

const SuccessPage = () => {
  const timer = AppSettings.timer;
  const navigate = useNavigate();

  const backToSignup = () => {
    navigate("/");
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      navigate("/");
    }, timer * 60 * 1000);
    return () => clearTimeout(timeOut);
  }, [timer, navigate]);

  return (
    <div id="success_page">
      <div className="success_page__header">
        <img src={Logo} className="success_page__logo" alt="evolve logo" />
        <h1>Congratulations!</h1>
        <p>You have been successfully signed up for Evovle Range Solutions.</p>
        <p>You may now log in to any Evolve Range Solutions products.</p>
      </div>
      <div className="success_page_back-to-signup">
        <Button
          id="success_page-button"
          type="button"
          variant="contained"
          onClick={backToSignup}
        >
          Back to signup screen
        </Button>
      </div>
    </div>
  );
};

export default SuccessPage;
