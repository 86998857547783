import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import SignupPage from './pages/SignupPage';
import SuccessPage from './pages/SuccessPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<SignupPage />} />
        <Route path='/success' element={<SuccessPage />} />
      </Routes>
    </Router>
  );
}

export default App;
