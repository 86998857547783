// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signup_page{
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex; 
    justify-content: center;
}

@media screen and (max-width: 560px) {
    #singup_page{
        width: 100vw;
        height: 100vh;
        background-image: none;
        background-color: red;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/pages/SignupPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,4BAA4B;IAC5B,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;QACb,sBAAsB;QACtB,qBAAqB;IACzB;;AAEJ","sourcesContent":["#signup_page{\n    min-height: 100vh;\n    width: 100%;\n    background-repeat: no-repeat;\n    background-position: center;\n    display: flex; \n    justify-content: center;\n}\n\n@media screen and (max-width: 560px) {\n    #singup_page{\n        width: 100vw;\n        height: 100vh;\n        background-image: none;\n        background-color: red;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
